<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Update Career
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>

    <b-modal id="image-properties-modal" title="Image Properties">
      <b-form>
        <b-form-group label="Alt Text">
          <b-form-input v-model="imageProperties.alt"></b-form-input>
        </b-form-group>
        <b-form-group label="Width">
          <b-form-input v-model="imageProperties.width" placeholder="e.g., 100px or 50%"></b-form-input>
        </b-form-group>
        <b-form-group label="Height">
          <b-form-input v-model="imageProperties.height" placeholder="e.g., 100px or auto"></b-form-input>
        </b-form-group>
        <b-form-group label="Alignment">
          <b-form-select v-model="imageProperties.alignment">
            <option value="">None</option>
            <option value="ql-align-left">Left</option>
            <option value="ql-align-center">Center</option>
            <option value="ql-align-right">Right</option>
          </b-form-select>
        </b-form-group>
        <b-button variant="primary" @click="insertImage">Insert</b-button>
      </b-form>
    </b-modal>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Title"
              label-for="blog-edit-name"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-name"
                  v-model="addCourseForm.title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Team"
              label-for="blog-edit-name"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="team"
              >
                <b-form-input
                  id="blog-edit-name"
                  v-model="addCourseForm.team"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="end_at"
              label-for="end_at"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="end_at"
              >
                <flat-pickr
                  v-model="addCourseForm.end_at"
                  class="form-control"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="level"
              label-for="blog-edit-name"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="level"
              >
                <b-form-input
                  id="blog-edit-name"
                  v-model="addCourseForm.level"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Position Code"
              label-for="blog-edit-name"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="position_code"
              >
                <b-form-input
                  id="blog-edit-name"
                  v-model="addCourseForm.position_code"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Related Position"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Show In"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.related_positions"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="PositionList"
                  multiple
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="location"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Sub name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addCourseForm.location"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
      
          <b-col md="3">
            <b-form-group
              label="Status"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
              >
                <v-select
                  id="blog-edit-statusList"
                  v-model="addCourseForm.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusList"
                  :reduce="(val) => val.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="type"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="type"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addCourseForm.type"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Benifits"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Sub name"
              >
                <b-form-tags
                  v-model="addCourseForm.benifits"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Slug"
              label-for="blog-edit-cgory"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Slug "
                rules="required"
              >
                <b-form-input
                  id="videor_link"
                  v-model="addCourseForm.slug"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Keywords"
              label-for="blog-content"
              class="mb-2"
            >
              <b-form-tags v-model="addCourseForm.keywords" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label=" Meta Title"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Meta Title"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="addCourseForm.meta_title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Meta Description"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Meta Description"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addCourseForm.meta_descprition"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                OG image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      :src="Tabimage"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="
                      refPreviewE2.src = Tabimage;
                      addCourseForm.og_image = '';
                    "
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Tab Image "
                      label-for="blog-edit-name"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                      >
                        <b-form-file
                          ref="refInputE2"
                          v-model="addCourseForm.og_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Description"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="description"
                rules="required"
              >
                <quill-editor
                  ref="descriptionEditor"
                  v-model="addCourseForm.description"
                  :options="getEditorOptions('descriptionEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Skills Languages "
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="skills_languages"
              >
                <quill-editor
                  ref="skillsLanguagesEditor"
                  v-model="addCourseForm.skills_languages"
                  :options="getEditorOptions('skillsLanguagesEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Remarks"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Remarks"
              >
                <quill-editor
                  ref="remarksEditor"
                  v-model="addCourseForm.remarks"
                  :options="getEditorOptions('remarksEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Competencies"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="competencies"
              >
                <quill-editor
                  ref="competenciesEditor"
                  v-model="addCourseForm.competencies"
                  :options="getEditorOptions('competenciesEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Job Details"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="job_details"
                rules="required"
              >
                <quill-editor
                  ref="jobDetailsEditor"
                  v-model="addCourseForm.job_details"
                  :options="getEditorOptions('jobDetailsEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Qualification"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="qualification"
              >
                <quill-editor
                  ref="qualificationEditor"
                  v-model="addCourseForm.qualification"
                  :options="getEditorOptions('qualificationEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Experience"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="experience"
              >
                <quill-editor
                  ref="experienceEditor"
                  v-model="addCourseForm.experience"
                  :options="getEditorOptions('experienceEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
            data-testid="carer-update-button"
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>

            <b-button
              v-if="isLoading"
              variant="primary"
              disabled
              class="mr-1"
            >
              Loading...
              <b-spinner small />
              <span class="sr-only" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive, getCurrentInstance } from '@vue/composition-api'
import Vue from 'vue'
import { quillEditor } from 'vue-quill-editor'
import { useRouter } from '@core/utils/utils'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import 'quill/dist/quill.snow.css'
// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup() {
    // const store = useStore();

    const refInputE2 = ref(null)
    const PositionList = ref([])
    const refPreviewE2 = ref(null)
    const statusList = ref([
      {
        id: 'passed',
        label: 'passed',
      },
      {
        id: 'draft',
        label: 'draft',
      },
      {
        id: 'published',
        label: 'published',
      },
    ])
    const { route } = useRouter()
    const { id } = route.value.params

    //   const productId = productSlug.substring(id.lastIndexOf('-') + 1)

    const Form = ref({
      title: '',
      description: '',
      basic_qualifications: '',
      preferred_qualifications: '',
      last_section: '',
      position_code: '',
      location: '',
      type: '',
      meta_title: '',
      meta_descprition: '',
      benifits: '',
      keywords: '',
      related_positions: [],
      slug: '',
      status: [],
      team: '',
      level: ' ',
      end_at: '',
      og_image: '',
    })
    store.dispatch('career/allPosition').then(response => {
      PositionList.value = response.data
    })
    const UpdateBlogForm = ref(Form)
    store.dispatch('career/GetCareer', { id }).then(response => {
      Form.value = response?.data.data
      Form.value.keywords = response?.data.data.keywords.split(',')
      Form.value.related_positions = JSON.parse(response?.data.data.related_positions)
      Form.value.status = JSON.parse(response?.data.data.status)
    })

    const Tabimage = ref('media/svg/files/blank-image.svg')

    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addCourseForm=reactive(Form)
    const addCourseFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          console.log(addCourseForm.value)
          formData.append('title', addCourseForm.value.title)
          formData.append('description', addCourseForm.value.description)
          
          formData.append("_method", "put");
          if (addCourseForm.value.remarks) {
            formData.append('remarks', addCourseForm.value.remarks)
          } 
          if (addCourseForm.value.competencies) {
            formData.append('competencies', addCourseForm.value.competencies)
          } 
         
          formData.append('slug', addCourseForm.value.slug)
          if (addCourseForm.value.keywords) {
            formData.append('keywords', addCourseForm.value.keywords)
          } if (addCourseForm.value.benifits) {
            formData.append('benifits', addCourseForm.value.benifits)
          }
          if (addCourseForm.value.benifits) {
            formData.append('benifits', addCourseForm.value.benifits)
          }
          if (addCourseForm.value.position_code) {
            formData.append('position_code', addCourseForm.value.position_code)
          }
          if (addCourseForm.value.last_section) {
            formData.append('last_section', addCourseForm.value.last_section)
          }
     
          if (addCourseForm.value.related_positions) {
            formData.append('related_positions',JSON.stringify(addCourseForm.value.related_positions))
          }
          if (addCourseForm.value.qualification) {
            formData.append('qualification', addCourseForm.value.qualification)
          }
          if (addCourseForm.value.experience) {
            formData.append('experience', addCourseForm.value.experience)
          }
          if (addCourseForm.value.job_details) {
            formData.append('job_details', addCourseForm.value.job_details)
          }
          if (addCourseForm.value.skills_languages) {
            formData.append('skills_languages', addCourseForm.value.skills_languages)
          }
          if (addCourseForm.value.location) {
            formData.append('location', addCourseForm.value.location)
          }
          if (addCourseForm.value.type) {
            formData.append('type', addCourseForm.value.type)
          }
          if (addCourseForm.value.meta_title) {
            formData.append('meta_title', addCourseForm.value.meta_title)
          }
          if (addCourseForm.value.meta_descprition) {
            formData.append('meta_descprition', addCourseForm.value.meta_descprition)
          }
          formData.append('status', addCourseForm.value.status)
          if (addCourseForm.value.team) {
            formData.append('team', addCourseForm.value.team)
          }
          if (addCourseForm.value.level) {
            formData.append('level', addCourseForm.value.level)
          }
          if (addCourseForm.value.end_at) {
            formData.append('end_at', addCourseForm.value.end_at)
          }
          if (addCourseForm.value.og_image) {
            formData.append('og_image', addCourseForm.value.og_image)
          }
  
          store.dispatch('career/UpdateCareer', { id, formData })
            .then(response => {
              Vue.swal({
                title: 'Career Updated ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }

    const { proxy } = getCurrentInstance()
    const imageProperties = reactive({
      src: '',
      alt: '',
      width: '',
      height: '',
      alignment: '',
    })
    let editorSelection = null
    let currentEditor = null

    const imageHandler = editorRef => {
      currentEditor = proxy.$refs[editorRef].quill
      editorSelection = currentEditor.getSelection()
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()
        formData.append('image', file)

        try {
          const token = localStorage.getItem('token')
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)

          xhr.onload = function () {
            if (xhr.status === 403) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            const json = JSON.parse(xhr.responseText)

            if (!json || typeof json.location !== 'string') {
              console.error(`Invalid JSON: ${xhr.responseText}`)
              return
            }

            imageProperties.src = json.location
            proxy.$bvModal.show('image-properties-modal')
          }

          xhr.onerror = function () {
            console.error(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
          }

          xhr.send(formData)
        } catch (error) {
          console.error('Image upload failed:', error)
        }
      }
    }

    const insertImage = () => {
      const editor = currentEditor
    console.log(currentEditor.getSelection())
      const range = editorSelection
      if (!range) {
        console.error('No selection found')
        return
      }
      const { src, alt, width, height, alignment } = imageProperties
  
      editor.insertEmbed(range.index, 'image', { src, alt, width, height, class: alignment })
      proxy.$bvModal.hide('image-modal-post')
         imageProperties.src = '';
      imageProperties.alt = '';
      imageProperties.width = '';
      imageProperties.height = '';
      imageProperties.alignment = '';
    }


  const linkHandler = editorRef => {
  const range = proxy.$refs[editorRef].quill.getSelection()
  if (range) {
    const url = prompt('Enter the URL')
    if (url) {
      const openInNewTab = confirm('Open in new tab?')
      const selectedText = proxy.$refs[editorRef].quill.getText(range)
      const targetAttribute = openInNewTab ? ' target="oooooooooo" rel="noopener noreferrer"' : ''
      const linkValue = `<a href="${url}"${targetAttribute}>${selectedText}</a>`

      // Remove the selected text
      proxy.$refs[editorRef].quill.deleteText(range.index, range.length)

      // Insert the link at the original selection index
      proxy.$refs[editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue)
    }
  }
}

    const getEditorOptions = editorRef => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            [{ size: SizeStyle.whitelist }],
    ['bold', 'italic', 'underline', 'strike'],
  
    ['clean'],
    ['undo', 'redo'],
    ['paragraph']
          ],
          handlers: {
            image: () => imageHandler(editorRef),
            link: () => linkHandler(editorRef)
          },
        },
      },
    })

    return {
     
      PositionList,
      statusList,
      id,
      addCourseForm,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      UpdateBlogForm,
      addCourseFormvalidate,
      Form,
      quillEditor,
      save,
      required,
       imageHandler,
      insertImage,
      imageProperties,
      linkHandler,
      getEditorOptions,
    }
  },

  components: {
    BCard,
    BMedia,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    quillEditor,
    vSelect,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>

<style>
.mb-2.col-12 {
  margin: -1px !important;
}
.border.rounded.p-2 {
  margin: 8px 1px !important;
}
.col-12 {
  margin: 0px;
}
.quill-editor {
  margin-left: 1px;
  width: 100%;
}
</style>
